@import "../variables/variables.scss";

.home-container {
    margin: 3rem 2rem;
    .tag-line-container {
        filter: drop-shadow(0 0 0.75rem $theamColor);
        flex-grow: 1;
        align-self: center;
        font-size: 2.5rem;
        text-align: center;
        min-height: 150px;
        text-transform: capitalize;
    }
    .tag-img-container{
        width: 60%;
        min-width: 60%;
        margin: 0 auto;
    }

    @media (max-width:750px){
        .tag-line-container {
            filter: drop-shadow(0 0 0.75rem $theamColor);
            flex-grow: 1;
            align-self: center;
            font-size: 2rem;
            text-align: center;
        }
    }
}

.chat-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-end;
    align-items: stretch;
    padding: 20px;
  }
  
  .chat-messages {
    flex: 1;
    overflow-y: auto;
  }
  
  .message {
    background-color: #f2f2f2;
    border-radius: 8px;
    margin: 8px 0;
    padding: 10px;
    max-width: 70%;
  }
  
  .message-content {
    font-size: 16px;
  }
  
  .chat-input {
    display: flex;
    margin-top: 20px;
  }
  
  .input-field {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px 0 0 8px;
  }
  
  .send-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 0 8px 8px 0;
    padding: 10px 20px;
    cursor: pointer;
  }

  .chat-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
  
  .chat-popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    height: 400px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    transform: translateY(100%);
  }
  
  .chat-popup.open {
    transform: translateY(0);
  }


.about{

  ul::before{
    content: "";
    position: absolute;
    display: block;
    height: 200px;
    width: 4px;
    background: #007bff;
    left: 5px;
  }
  // li{
  //   list-style: mark;
  // }
  li::marker {
    content: "";
    display: inline-block;
    width:5px;
    height:5px;
    border-radius: 50%;
    background-color: red;
    color: black;
    font-weight: bold;
    text-align: center;
    line-height:5px;
    margin-right: 0.5em;
  }
  li {
    color: black;
  }
}