

* {
    padding: 0;
    margin: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@import "./react//home.scss";
@import "./react/footer.scss";





$grey: #1f1f1f;

*,
*:before,
*:after {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}



.slider-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin: 25px 0;

   .slider {
      position: relative;
      height: 500px;
      width: 350px;
      overflow: hidden;

      @media only screen and (max-width: 375px) {
         height: 400px;
         width: 300px;
      }

      .image {
         position: absolute;
         height: 100%;
         width: 100%;
         background-size: cover;
         background-repeat: no-repeat;
         background-position: center;
         will-change: transform, opacity;

         &:hover {
            cursor: grab;
         }

         &:active {
            cursor: grabbing;
         }
      }
   }

   .buttons {
      margin-top: 12px;

      button {
         outline: none;
         border: none;
         user-select: none;
         padding: 10px 12px;
         transform: skewY(-5deg) rotate(5deg);
         background-color: $grey;
         font-family: inherit;
         font-size: inherit;
         color: white;
         transition: .07s ease-out transform;

         &:first-of-type {
            margin-right: 15px;
         }

         &:hover {
            cursor: pointer;
         }

         &:active {
            transform: scale(.85) skewY(-5deg) rotate(5deg);
         }
      }
   }
}

.thumbnails {
   display: flex;
   justify-content: center;

   .thumbnail-container {
      position: relative;
      height: 120px;
      width: 90px;

      @media only screen and (max-width: 375px) {
         height: 70px;
         width: 50px;
      }

      &:hover {
         cursor: pointer;
      }

      &:not(:last-of-type) {
         margin-right: 5px;
      }

      img {
         height: 100%;
         width: 100%;
         object-fit: cover;
         object-position: center;
      }

      .active-indicator {
         position: absolute;
         top: 0;
         left: 0;
         height: 100%;
         width: 100%;
         pointer-events: none;
         transform: scaleX(0);
         transform-origin: left;
         background-color: $grey;
         transition: 1s cubic-bezier(0.56, 0.03, 0.12, 1.04) transform;

         &.active {
            transform: scaleX(1);
         }
      }
   }
}